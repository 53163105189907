import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Select,
  Input,
  Field,
  Control,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv, Table } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Rep Max Calculator",
    link: "/rep-max-calculator/",
  },
]
const seeAlsoArray = [
  "/bmi-calculator",
  "/body-mass-fat-calculator",
  "/date-and-time-calculator",
  "/fuel-consumption-calculator",
  "/percentage-calculator",
  "/shopping-calculator",
  "/tax-cost-calculator",
  "/tip-calculator",
]

function TableBody({ weight, reps }) {
  if (!weight) return null

  let data = []

  let lomonerm = weight * Math.pow(reps, 1 / 10)

  let brzonerm = weight * (36 / (37 - reps))
  let eplonerm = weight * (1 + reps / 30)
  let mayonerm = (weight * 100) / (52.2 + 41.9 * Math.exp(-1 * (reps * 0.055)))
  let ocoonerm = weight * (1 + reps * 0.025)
  let watonerm = (weight * 100) / (48.8 + 53.8 * Math.exp(-1 * (reps * 0.075)))
  let lanonerm = (weight * 100) / (101.3 - 2.67123 * reps)

  let avgonerm =
    (lomonerm +
      brzonerm +
      eplonerm +
      mayonerm +
      ocoonerm +
      watonerm +
      lanonerm) /
    7

  if (reps === 1) {
    data.push({
      RM: "1RM",
      valueOne: weight,
      valueTwo: weight,
      valueThree: weight,
      valueFour: weight,
      valueFive: weight,
      valueSix: weight,
      valueSeven: weight,
      valueAte: weight,
    })
  } else {
    data.push({
      RM: "1RM",
      valueOne: Math.floor(avgonerm),
      valueTwo: Math.floor(lomonerm),
      valueThree: Math.floor(brzonerm),
      valueFour: Math.floor(eplonerm),
      valueFive: Math.floor(mayonerm),
      valueSix: Math.floor(ocoonerm),
      valueSeven: Math.floor(watonerm),
      valueAte: Math.floor(lanonerm),
    })
  }

  var i = 2
  for (i; i <= 10; i++) {
    let lomrm = Math.floor(lomonerm / Math.pow(i, 1 / 10))
    let brzrm = Math.floor((brzonerm * (37 - i)) / 36)
    let eplrm = Math.floor(eplonerm / (1 + i / 30))
    let mayrm = Math.floor(
      (mayonerm * (52.2 + 41.9 * Math.exp(-1 * (i * 0.055)))) / 100
    )
    let ocorm = Math.floor(ocoonerm / (1 + i * 0.025))
    let watrm = Math.floor(
      (watonerm * (48.8 + 53.8 * Math.exp(-1 * (i * 0.075)))) / 100
    )
    let lanrm = Math.floor((lanonerm * (101.3 - 2.67123 * i)) / 100)

    let avgrm = Math.floor(
      (lomrm + brzrm + eplrm + mayrm + ocorm + watrm + lanrm) / 7
    )

    data.push({
      RM: i + "RM",
      valueOne: avgrm,
      valueTwo: lomrm,
      valueThree: brzrm,
      valueFour: eplrm,
      valueFive: mayrm,
      valueSix: ocorm,
      valueSeven: watrm,
      valueAte: lanrm,
    })
  }

  return data.map(item => (
    <tr key={item.RM}>
      <td>{item.RM}</td>
      <td>
        <strong>{item.valueOne}</strong>
      </td>
      <td>{item.valueTwo}</td>
      <td>{item.valueThree}</td>
      <td>{item.valueFour}</td>
      <td>{item.valueFive}</td>
      <td>{item.valueSix}</td>
      <td>{item.valueSeven}</td>
      <td>{item.valueAte}</td>
    </tr>
  ))

  // return rows;

  // return data.map(item => <TableRow key={item.RM} data={item} />);
}

function RepMaxCalculator(props) {
  let [weight, setWeight] = useState("")
  let [reps, setReps] = useState("1")

  function changeWeight(e) {
    setWeight(e.target.value)
  }

  function changeReps(e) {
    setReps(e.target.value)
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Rep Max Calculator"
        description="Rep Max Calculator, Give an estimated rep max. Simply enter the weight used, and how many repetitions you were able to perform."
        keywords={[
          "rep max ,max rep calculation,rm gym calculator,one repetition calculator,rm calc,max calculator,rep max calculator,rm Average calculator,1rm calculator,strength level.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Rep Max Calculator</Title>
        <FlexDiv maxWidth="500px" margin="auto">
          <Field>
            <Control>
              <StaticButton>I did (Weight)</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                className="borderedRight"
                type="number"
                value={weight}
                onChange={changeWeight}
                placeholder="Weight"
                pattern="[0-9]*"
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <StaticButton>for</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Select borderRadius="0">
                <select
                  aria-label="unitSelector"
                  className="staticSelect"
                  value={reps}
                  onChange={changeReps}
                  onBlur={changeReps}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </Select>
            </Control>
            <Control>
              <StaticButton
                bottomRightRadius="4px"
                topRightRadius="4px"
                bottomLeftRadius="0"
                TopLeftRadius="0"
              >
                reps
              </StaticButton>
            </Control>
          </Field>
        </FlexDiv>
        <br />
        <Table>
          <thead>
            <tr>
              <th>RM</th>
              <th>Average</th>
              <th>
                <a
                  href="http://en.wikipedia.org/wiki/One-repetition_maximum#Lombardi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lombardi
                </a>
              </th>
              <th>
                <a
                  href="http://en.wikipedia.org/wiki/One-repetition_maximum#Brzycki"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Brzycki
                </a>
              </th>
              <th>
                <a
                  href="http://en.wikipedia.org/wiki/One-repetition_maximum#Epley"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Epley
                </a>
              </th>
              <th>
                <a
                  href="http://en.wikipedia.org/wiki/One-repetition_maximum#Mayhew_et_al."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mayhew
                </a>
              </th>
              <th>
                <a
                  href="http://en.wikipedia.org/wiki/One-repetition_maximum#O.27Conner_et_al."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  O'Conner
                </a>
              </th>
              <th>
                <a
                  href="http://en.wikipedia.org/wiki/One-repetition_maximum#Wathan"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Wathan
                </a>
              </th>
              <th>
                <a
                  href="http://en.wikipedia.org/wiki/One-repetition_maximum#Lander"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lander
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <TableBody weight={weight} reps={reps} />
          </tbody>
        </Table>

        <br />

        <FlexDiv maxWidth="400px">
          <SmallImg filename="gym.png" alt="gym rep max calculator" />
        </FlexDiv>
        <br />
        <h3>Rep Max Calculator</h3>
        <p>
          Give an estimated rep max. Simply enter the weight used, and how many
          repetitions you were able to perform.
        </p>
        <br />
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default RepMaxCalculator
